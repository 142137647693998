<template>
  <div>
  	<div>
  		<div v-if="user.parent_id" class="box referral-link-box">
	  		Referral Link: <a class="referral-link" :href="landingUrl + '?referral_code=' + user.referral_code">{{ landingUrl + '?referral_code=' + user.referral_code }}</a>
	  		<div @click="copyCode" class="btn">Copy</div>
	  		<input type="hidden" id="referral-link" :value="landingUrl + '?referral_code=' + user.referral_code">
	  	</div>
	  	<div v-if="user.parent_id" class="box referral-list-box">
	  		<div class="block">
	  			<div class="hdr">
	  				<div class="ttl">Referrals</div>
	  			</div>
	  			<div class="referrals-table">
		  			<Table
			            class="bank-offer"
			            :options="referralsTableData"
			            @change-page="getReferrals"
			            @scroll-top="toElTop"
			            :key="referralsTableKey"
			          />
			    </div>
	  		</div> 
	  	</div>
  	</div>
  </div>
</template>

<script>
import Employment from '@/components/img/Employment.vue'
import Table from '@/components/elem/Table.vue'

export default {
	components: {
		Employment,
		Table,
	},
	data() {
		return {
			referralsTableKey: 0,
			landingUrl: process.env.VUE_APP_LANDING_URL,
			referralsTableData: {
			  objName: 'referralsTableData',
		      colsWidth: ['40%', '40%', '20%'],
		      header: [
		        { ttl: 'Email'},
		        { ttl: 'Fullname' },
		        { ttl: 'Level' },
		      ],
		      body: [],
		      paginationData: null,
		    }
		}
	},
	methods: {
		copyCode() {
          let codeToCopy = document.querySelector('#referral-link')
          codeToCopy.setAttribute('type', 'text')
          codeToCopy.select()
          try {
            var successful = document.execCommand('copy');
            var msg = successful ? 'successful' : 'unsuccessful';
            this.$noty.success('Link was copied ' + msg);
          } catch (err) {
            this.$noty.error('Oops, unable to copy');
          }
          codeToCopy.setAttribute('type', 'hidden')
          window.getSelection().removeAllRanges()
		},
		getReferrals (page = 1) {
			const that = this
			axios.get(process.env.VUE_APP_BACKEND_API_URL + 'admin/referrals?page=' + page)
		        .then(({ data }) => {
		          that.referralsTableData.paginationData = data
		          that.referralsTableData.body = []
		          data.data.forEach(el => {
		            that.referralsTableData.body.push([
		              el.email,
		              el.full_name,
		              (el.depth - that.user.depth),
		            ])
		          })
		        })
		        .catch(err => console.dir(err))
		},
		toElTop (el) {
	      this.$refs.os.osInstance().scroll({ el: el, margin: 10 }, 1500)
	    },
	},
	computed: {
	    user () {
	      return this.$store.state.user
	    },
	},  
	watch: {
		user: function(user) {
			if (user) {
				this.getReferrals()
			}
		}
	}
}
</script>

<style>
	.referral-link {
		color: #09090b;
		margin-left: 10px;
	}

	.referral-link-box {
		padding: 2rem;
	}

	.referral-link-box {
		display: flex;
		flex-direction: row;
		align-items: center;
		background: transparent !important;
		border: 1px solid #ababb2 !important;
		color: #09090b !important;
	}

	.referral-list-box{
		padding-top: 2rem;
		padding-bottom: 2rem;
		background: transparent !important;
		color: #09090b !important;
		box-shadow: none !important;
	}

	.referral-link-box .hdr {
		display: flex;
	    align-items: center;
	    margin-left: 0.75rem;
	    margin-bottom: 0.5rem;
	}

	.referral-link-box .btn {
		padding: 1rem 1.75rem;
	    margin: 0.5rem;
	    background: #1763fb;
	    border-radius: 6px;
	    line-height: 1.1;
	    margin-left: 20px;
		color: #fff;
	}

	.referral-link-box .hdr .img {
		width: 1.75rem;
        height: 1.75rem;
        margin-right: 0.5rem;
	}

	.referrals-table {
		margin-top: 20px;
	}

	.block {
        width: 100%;
	}
</style>
